* { font: sans-serif; text-align: center; }

.Main {
  padding: 130px;
}

h2, p, {
  text-align: center;
  height: 20%;
}

.Socialbuttons, {
  text-align: center;
  margin: auto;
}

.Main {
  height: 50px;
}

.css-faj5gh {
  position: fixed;
  left:0;
  bottom:0;
  right:0;

}